<template>
  <div class="services-cul">  
    <!-- <div class="headerr active">
      <h1><strong>Project</strong> Detail</h1>
          <h5>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has,</h5>
      <div class="cache"></div>
    </div> -->
    <Loader v-if="!news" />
      <div class="container"> 
       
          <div class="row" style="margin-bottom:20px;margin-left:5%">
              <div class="col-md-6">  

                <carousel class="carousel-project" :per-page="1" :mouse-drag="false" v-bind:autoplay="true" v-bind:loop="true">
                  <slide v-if="news">
                      <div class='card'>
                          <div class='card-img'>
                              <img v-bind:src="news.news_cover.original_url"/>
                             
                          </div>
                        </div>
                  </slide>
                  
                </carousel>


              </div>
            <div class="col-md-6" v-if="news">
              <div class="item">
                <div class="info">
                  <!-- <div class="user"><p class="subtitle" style="color:#FAD605">{{news.category.name}}</p></div> -->
                   <h5 >{{news.title}}</h5>
                    <p>{{ news.body }} </p>
                    <!-- <div class="user"><i class="fa fa-arrow-circle-right"></i>Status: <p class="subtitle">{{news.status}}</p></div> -->
                    
                    <!-- <div class="user"><i class="fa fa-arrow-circle-right"></i>Duration: <p class="subtitle">{{project.duration}}</p></div> -->
                    <!-- <div class="user"><i class="fa fa-arrow-circle-right"></i>Cost: <p class="subtitle">Completed</p></div> -->
                  </div> 
              </div>
             
             
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue';
export default {
data(){
  return{
    news:''
  }
},
  name:'news-page',
  components:{
    Loader
  },
  mounted() {
  this.id = this.$route.params.id;
  this.newsDetail()
  console.log(this.id)
  
},
methods: {
  newsDetail(){
    this.$api
  .request({ auth: false })
  .get(`news-detail/${this.id}`)
  .then((response) => {
    this.news = response.data.data
    // console.log(response.data);
  })
  .catch((error) => {
    console.log(error);
  });
  },
}

}
</script>

<style lang="scss" scoped>
.services-cul{
padding: 30px 0px 0px 0px;
width: 100%;
margin-left: -3vh;

}

.headerr{
  position: relative;
  left: 55%;
  margin-bottom: 1vh;
    
  transform: translate(-50%, -50%);
  color: #004e7f;
  font-family: Helvetica;
  font-size: 2.5vw;
    
}
.headerr .cache{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all .8s ease-out 0s;
}
.headerr.active .cache{
  transition: all .8s ease-out .3s;
  left: -100%;
}
.cache:before{
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 1%;
  height: 0;
  transition: all .3s ease-out .8s;
  background-color: #FAD605
}
.headerr.active .cache:before{
  height: 100%;
  transition: all .3s ease-out 0s;
}
.headerr h1{
  font-weight: 10;
  margin: 0;
  font-size: 20px;
  text-align: start;
  padding: 5px 0px 5px 10px;
}
.headerr h5{
  margin: 0;
    color: gray;
    font-size: 15px;
    text-align: start;
    padding: 0px 0px 0px 10px;
}

.card {
  width: 20rem;
  display: flex;
  gap: 1rem;
  border: none;
}

.card-img img{
  display: flex;
  height: 30rem;
  width: 100vh;

}

.blog .item{
  background-color: #fff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.blog .item .more{
  position: absolute;
  right: 0px;
  bottom: 20px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  color: #004e7f;
  font-size: 19px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  transform: translate(0 , 100px);
  transition: all 0.3s ease-in-out;
}

.blog .item:hover .more{
  transform: translate(0 , 0)
}
.item .img{
  /*clip-path: polygon(0 0, 100% 0, 100% 70%, 0 100%, 0 75%);*/
}

.item .info{
  padding: 5px;
  position: relative;
  text-align: start;
}

.item .info h5{
  color: #004e7f;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
  
}
.subtitle{
  color: #004e7f;
  padding: 5px;
}

.item .info .user{
  color: #F6B50E;
  font-weight: bold;
  font-size: 19px;
  display: flex;
}

.item .info .user i{
  margin-right: 5px;
  font-size: 20px;
  padding: 5px;
}
</style>