<template>
    <div>
        <Navbar/>
        <Landing/>
        <ProjectList/>
        <Footer/>

    </div>
</template>

<script>

import Landing from '@/components/project/Landing.vue'
import ProjectList from '@/components/project/ProjectList.vue'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
    export default {
        name:'project-page',
        components:{
            Landing,
            ProjectList,
            Navbar,
            Footer
        }
        
    }
</script>

<style lang="scss" scoped>

</style>