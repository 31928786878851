<template>
    <div>
      <Navbar/>
      <div class="contact-page">
      <section class="contact" id="contact">
          <div class="container">
             
              <div class="row">
                  <div class="col-md-5">
                      <div class="title">
                          <div class="expertise-title">
                              <h3 >Contact <span>Information</span></h3>
                          </div>
                      </div>
                      <div class="content-contact">
                          <!-- Info-1 -->
                          <!-- <div class="info">
                              <h4 class="d-inline-block"><vue-fontawesome icon="phone" color="#6acde4"  size="2" ></vue-fontawesome>PHONE :
                                  <br>
                                  <span>0933213123, 0912445154, 0920564045</span></h4>
                          </div> -->
                          <!-- Info-2 -->
                          <div class="info">
                              <h4 class="d-inline-block"><vue-fontawesome icon="envelope" color="#004e7f" size="2" ></vue-fontawesome>EMAIL :
                                  <br>
                                  <span>info@crtecsc.com</span></h4>
                          </div>
                          <!-- Info-3 -->
                          <div class="info">
                              
                              <h4 class="d-inline-block"><vue-fontawesome icon="address-card" color="#004e7f"  size="2" ></vue-fontawesome>ADDRESS :<br>
                                  <span>Sichuan Province, Chengdu City, Jincheng Avenue, Gaoxin District </span>
                                  
                                </h4>
                          </div>
                      </div>
                  </div>
  
                  <div class="col-md-7 form-container">
  
                      <form @submit.prevent="sendEmail">
                          <div class="row">
                              <div class="col-sm-6">
                                  <input type="text" name="name" v-model="name" class="form-control" placeholder="Name">
                              </div>
                              <div class="col-sm-6">
                                  <input type="email" name="email" v-model="email" class="form-control" placeholder="Email">
                              </div>
                              <!-- <div class="col-sm-12">
                                  <input type="text" class="form-control" placeholder="Subject">
                              </div> -->
                          </div>
                          <div class="form-group">
                            <input type="tel" name="phone" v-model="phone" class="form-control" placeholder="Phone Number">
                          </div>
                          <div class="form-group">
                              <textarea class="form-control" name="message" v-model="message" rows="5" id="comment" placeholder="Message"></textarea>
                          </div>
                          <button class="btn btn-block" type="submit">Send Now!</button>
                      </form>
                  </div>
              </div>
          </div>
      </section>
      <Footer/>
  </div>
     
    </div>
  </template>
  
  <script>
  import emailjs from 'emailjs-com';
import Navbar from '@/components/Navbar.vue';
  import Footer from '@/components/Footer.vue'
  export default {
      components:{
          Footer,
          Navbar
  
      },
       data(){
      return{
          name: '',
        email: '',
        message: '',
        phone:''
      }
    },
    methods:{
       sendEmail(e) {
        try {
          emailjs.sendForm('service_fiyu6h2', 'template_3g7ilj5', e.target,
          'Wa7-gPNXuBXrD3Fb5', {
            name: this.name,
            email: this.email,
            message: this.message,
            phone: this.phone
          }).then (
           
            this.$toasted.success("Your Message Sucessfully Sent!")
  
            
          )
  
        } catch(error) {
            console.log({error})
        }
        // Reset form field
        this.name = ''
        this.email = ''
        this.message = ''
        this.phone = ''
      },
    }
    
    
  
  }
  </script>
  
  <style>
  .responsive-map{
  
      overflow: hidden;
      padding-bottom:56.25%;
      position:relative;
      height:0;
      }
      .responsive-map iframe{
      left:0;
      top:0;
      height:90%;
      width:100%;
      position:absolute;
      }
  .contact-page {
      background: #f2f2f2;
      
  }
  .form-container{
      box-shadow: 0 2px 4px 0 #c9cbcb;
     border-radius: 20px;
      padding: 20px 30px 30px 20px;
  
  }
  .d-inline-block{
      color: #000;
  }
  .contact{
    margin-bottom: 30px;
      padding-top: 30px;
  }
  
  .contact .heading h2 {
      font-size: 30px;
      font-weight: 700;
      margin: 0;
      padding: 0;
  
  }
  
  .contact .heading h2 span {
      color: #ff9100;
  }
  
  .contact .heading p {
      font-size: 15px;
      font-weight: 400;
      line-height: 1.7;
      color: #000;
      margin: 20px 0 60px;
      padding: 0;
  }
  
  .contact .form-control {
      padding: 10px;
      font-size: 13px;
      margin-bottom: 10px;
      border: 0;
      border-radius: 10px;
  }
  
  .contact button.btn {
      padding: 10px;
      border-radius: 10px;
      font-size: 15px;
      background: #004e7f;
      color: #ffff;
      width: 100%;
  }
  
  .contact .title h3 {
      font-size: 18px;
      font-weight: 600;
  }
  
  .contact .title p {
      font-size: 14px;
      font-weight: 400;
      color: #000;
      line-height: 1.6;
      margin: 0 0 40px;
  }
  
  .contact .content-contact .info {
      margin-top: 30px;
  }
  .contact .content-contact .info i {
      font-size: 30px;
      padding: 0;
      margin: 0;
      color: #02434b;
      margin-right: 20px;
      text-align: center;
      width: 20px;
  }
  .contact .content-contact .info h4 {
      font-size: 13px;
      line-height: 1.4;
  }
  
  .contact .content-contact .info h4 span {
      font-size: 13px;
      font-weight: 300;
      color: #000;
  }
  
  </style>