import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './services/api'
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import VueCarousel from 'vue-carousel';
import "font-awesome/css/font-awesome.min.css";
import Toasted from 'vue-toasted';

Vue.component('VueFontawesome', require('vue-fontawesome-icon/VueFontawesome.vue').default);
 
Vue.use(Toasted)
 
Vue.use(VueCarousel);

Vue.config.productionTip = false
Vue.prototype.$api = api
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
