<template>
    <div>
        <Navbar/>
        <Landing/>
        <DetailList/>
        <Footer/>
    </div>
</template>

<script>
import DetailList from '@/components/project/projectDetail/DetailList.vue'
import Landing from '@/components/project/projectDetail/Landing.vue'
import Footer from '@/components/Footer.vue';
import Navbar from '@/components/Navbar.vue';
    export default {
        components:{
            DetailList,
            Landing,
            Navbar,
            Footer
        }
        
    }
</script>

<style lang="scss" scoped>

</style>