<template>
    <div >
        <div class="headerr active">
            <h1>Our <strong>Projects</strong> </h1>
                <h5>CRTEC people perform their duties conscientiously, act proactively, and strictly control the gates, which not only promote the smooth progress of the project construction, but also ensure the quality and safety of the project. They are well received by the construction administrative department and the owners.</h5>
            <div class="cache"></div>
          </div>
        <div class="container">
            <div class="row" style="margin-left: -6vh;">
              <Loader v-if="!projectList" />
                <div class="col-md-4 col-sm-4" v-for="(project, index) in projectList" :key="index" @click=" $router.push({ name: 'DetailProject', params: { id: project.id }, })">
                    <div class="service-post">
                        <div class="snip1107 blue">
                          <img v-bind:src="project.project_image.original_url" height="250rem" alt="">
                            <figcaption>
                                <div><span></span></div>                              
                                <div>
                                <h3>{{project.title.substring(0,60)+".."}}</h3>
                              </div>
                            </figcaption>
                        </div>
                        <!-- service-content -->
                        <div class="service-hover"></div>
                    </div>
                </div>
              
            </div>
        </div>
    </div>
  
</template>

<script>

import Loader from '../Loader.vue';
export default {
    name:'our-expertise',
    data(){
    return{
      projectList:null

    }
  },
  components:{
    Loader
  },
    mounted() {
    
    this.allProject()
   
  },
  methods:{
    allProject() {
        this.$api.request({ auth: false })
          .get("/projects")
          .then((response) => { this.projectList = response.data.data })
          .catch((error) => console.log(error));
    },
  }

}
</script>

<style>

  .content-103{
      padding-bottom:50px;
    
  }
 
  .col-md-4 {
    margin-bottom: 10px;
    
  }
  
 
  .headerr{
    position: relative;
    left: 55%;
    margin-top: 10vh;
    margin-bottom: 1vh;
      
    transform: translate(-50%, -50%);
    color: #004e7f;
    font-family: Helvetica;
    font-size: 2.5vw;
      
  }
  .headerr .cache{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all .8s ease-out 0s;
  }
  .headerr.active .cache{
    transition: all .8s ease-out .3s;
    left: -100%;
  }
  .cache:before{
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 1%;
    height: 0;
    transition: all .3s ease-out .8s;
    background-color: #FAD605
  }
  .headerr.active .cache:before{
    height: 100%;
    transition: all .3s ease-out 0s;
  }
  .headerr h1{
    font-weight: 10;
    margin: 0;
    font-size: 20px;
    text-align: start;
    padding: 5px 0px 5px 10px;
  }
  .headerr h5{
    margin: 0;
      color: gray;
      font-size: 15px;
      text-align: start;
      padding: 0px 0px 0px 10px;
      width: 80%;
  }
 
 
.snip1107 {
  font-family: 'Raleway', Arial, sans-serif;
  color: #fff;
  position: relative;
  overflow: hidden;
  margin: 10px;
  
  width: 100%;
  height: 100%;
  background: #000000;
  border-radius: 2%;
  text-align: center;
}
.snip1107 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.snip1107 img {
  opacity: 1;
  width: 100%;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
}
.snip1107 figcaption {
  position: absolute;
  bottom: 0;
  left: 0%;
  right: 0%;
  top: 15%;
  height: 100%;
  width: 100%;
}
.snip1107 figcaption > div {
  overflow: hidden;
  width: 100%;
  position: relative;
  height: 50%;
}
.snip1107 h3{
    height: 100%;
    font-size: 1.2rem;
    
}
.snip1107 span {
    
}
.snip1107 h3,
.snip1107 span {
  margin: 0;
  padding: 10px 15px;
  display: inline-block;
  width: 100%;
  font-weight: 800;
  text-transform: uppercase;
}
.snip1107 span {
  background: #ffffff;
  color: #4d4d4d;
  position: relative;
  bottom: 0;
  left: 0;
  position: absolute;
  -webkit-transform: translate3d(0%, 100%, 0);
  transform: translate3d(0%, 100%, 0);
}
.snip1107 h3 {
  background: #333333;
  -webkit-transform: translate3d(0%, 0%, 0);
  transform: translate3d(0%, 0%, 0);
}
.snip1107 a {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  color: #ffffff;
}
.snip1107.blue {
  background: #0a212f;
}
.snip1107.blue h3 {
    background: rgba(226, 226, 226, 0.8);
    color: #004e7f;
    font-size: 15px;
    text-align: start;
}


</style>