<template>
    <div class="services-cul">  
        <div class="container"> 
            <div class="row">
                <div class="col-md-6">  
                     <Project/>
                </div>
              <div class="col-md-6">
                <div class="headerr active">
                    <h1><strong>Latest</strong> News</h1>
                        <h5>Find out how we bring ideas to life, keep up to date with industry news and our insights on pioneering the innovations of the future</h5>
                    <div class="cache"></div>
                  </div>
                <div class="overflow">
                    <Loader v-if="!newsList" />
                    <!-- news 1 -->
                    <ul class="news-card" v-for="(news, index) in newsList" :key="index" >
                        <li class="card">
                            <img class="featured-image" v-bind:src="news.news_cover.original_url"/>
                            <article class="card-body">
                                <header>
                                        <span class="pre-heading">{{news.title }}</span>
                                        <div class="title" >
                                            <h3>{{ news.body.substring(0,100)+".." }}</h3>
                                            <div class="user" @click=" $router.push({ name: 'DetailNews', params: { id: news.id }, })"><i class="fa fa-arrow-circle-right"></i> Read More</div>

                                        </div>
                                        <p class="meta">
                                            <time class="updated" datetime="" itemprop="datePublished">{{news.created_at }}</time>
                                        </p>
                                </header>
                              
                            </article>
                        </li>   
                        </ul>
                        
                </div>
               
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Project from './Project.vue';
import Loader from '@/components/Loader.vue'
export default {
    name:'news-page',
    data(){
        return{
            newsList:null
        }
    },
    components:{
        Project,
        Loader
    },
    mounted() {
    
    this.allNews()
   
  },
  methods:{
        allNews() {
        this.$api.request({ auth: false })
          .get("/news")
          .then((response) => { this.newsList = response.data.data })
          .catch((error) => console.log(error));
    },
  }

    }


</script>

<style lang="scss" scoped>
.services-cul{
  padding: 30px 0px 0px 0px;
  width: 100%;
  margin-bottom: 10vh;
  
}
.overflow {
    height:67vh;
    overflow-y: scroll;
    width: 100%;

}
// Recruitler.com's _card.scss
.news-card{
    margin-top: 1rem;
}
.card{
    border: none;
	background: #fff;
    border-radius: 5px;
    display:flex;
    flex-direction: column;
    margin-bottom: 20px;
    .card-body{
        display:flex;
        flex-flow: row wrap;
        padding: 24px 0px 30px 30px;
    }
    header{
        flex: 100%;
        margin-top: -3vh;
        margin-right: -20vh;
        margin-left: -2vh;
        text-align: start;
        position: relative;
        .pre-heading{
            
        }
    }
    .meta{
        margin-bottom: 22px;
    }
   
    .featured-image{
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        overflow:hidden;
        width: 90vh;
        height: 20vh; 
    }

}




// Horizontal kicks in
@media only screen and (min-width: 768px) {
	.card{
        flex-direction: row;
        max-height: 279px; 
        h3{
            font-size: 15px;
        }
        .featured-image{
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            border-top-right-radius: 0;
            max-width: 390px;
            max-height: 279px; // desktop horizontal cards with featured image
        }
	}
}
.headerr{
    position: relative;
    left: 55%;
    margin-top: 10vh;
    margin-bottom: 1vh;
      
    transform: translate(-50%, -50%);
    color: #004e7f;
    font-family: Helvetica;
    font-size: 2.5vw;
      
  }
  .headerr .cache{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all .8s ease-out 0s;
  }
  .headerr.active .cache{
    transition: all .8s ease-out .3s;
    left: -100%;
  }
  .cache:before{
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 2%;
    height: 0;
    transition: all .3s ease-out .8s;
    background-color: #FAD605
  }
  .headerr.active .cache:before{
    height: 100%;
    transition: all .3s ease-out 0s;
  }
  .headerr h1{
    font-weight: 10;
    margin: 0;
    font-size: 20px;
    text-align: start;
    padding: 5px 0px 5px 10px;
  }
  .headerr h5{
    margin: 0;
      color: gray;
      font-size: 15px;
      text-align: start;
      padding: 0px 0px 0px 10px;
  }

// monitor screen sizes
@media only screen and (min-width: 1280px) {
	.card{
        h3{
            font-size: 15px;
        }
    }
}



h3{
    font-size: 15px;
    line-height: 1.2;
    
    color: #6e6969;
    margin: .5em 0;
}
.pre-heading {
    color: #004e7f;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    font-weight: bold;
}
.meta {
    color: #fab405;
    font-size: 13px;
}
.author {
    text-transform: uppercase;
}
// NON-CARD STYLES
ul{
    display:block;
    margin: 0 auto;
    max-width: 100%;
    padding: 0px 10px 50px 30px ;
    margin-bottom: -95px;
}




</style>