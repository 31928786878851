<template>
    <div>
        <Navbar/>
        <Landing/>
        <News/>
        <Service/>
        <Footer/>
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import Landing from '@/components/Landing.vue';
import News from '@/components/News.vue'
import Service from '@/components/Service.vue';
import Footer from '@/components/Footer.vue';
    export default {
        name:'home-page',
        data(){
            return{

            }

        },
        components:{
            Navbar,
            Landing,
            News,
            Service,
            Footer
        }
        
    }
</script>

<style lang="scss" scoped>

</style>