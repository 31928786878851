<template>
    <div>
        <Navbar/>
        <Landing/>
        <DetailList/>
        <Footer/>

    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import Landing from '@/components/news/newsDetail/Landing.vue'
import DetailList from '@/components/news/newsDetail/DetailList.vue'
    export default {
        components:{
            Navbar,
            Footer,
            Landing,
            DetailList
        }
        
    }
</script>

<style lang="scss" scoped>

</style>