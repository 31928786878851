<template>
    <div >
        <section class="blog" data-scroll-index="4">
            <div class="container">
              <!-- header of section -->
              <div class="blog-head text-center">
                <h2>Collaboration Between </h2>
                <h6>Chines & International <br>Engineers & Architects</h6>
              </div>
    
              <!-- blog items -->
              <div class="row" >
                <div class="col-md-6 col-lg-4" style="margin-bottom:3vh">
                  <div class="item">
                    <div class="info">
                       
                       <h5>Engineering Design</h5>
                        <p>
                          <i class="fa fa-star" aria-hidden="true"></i>Assist the entrusting party to complete the project survey and design bidding and contract signing;
                          <br>
                          <i class="fa fa-star" aria-hidden="true"></i> Review the project survey and design work plan and progress plan submitted by the survey and design unit;
                          

                        </p>
                        <router-link to="/engineeringDesign" style="text-decoration:none">
                        <div class="user"><i class="fa fa-arrow-circle-right"></i> View More</div>
                      </router-link>
                      </div>
                    <div class="img">
                      <img src="@/assets/images/design.jpeg" height="250rem" alt="">
                    </div>
                    
                  </div>
                </div>
    
                <div class="col-md-6 col-lg-4" style="margin-bottom:3vh">
                    <div class="item">
                        <div class="info">
                           
                            <h5>Whole Process Engineering Consulting</h5>
                            <p><i class="fa fa-star" aria-hidden="true"></i> Comprehensive consulting for investment decision-making. Including: project planning, investment opportunity research, project feasibility study and assessment, project environmental ....</p>
                            <router-link to="/wholeProcessEngineeringConsulting" style="text-decoration:none">
                              <div class="user"><i class="fa fa-arrow-circle-right"></i> View More</div>
                            </router-link>
                          </div>
                      <div class="img">
                        <img src="@/assets/images/wholeprocess.jpeg" height="250rem" alt="">
                      </div>
                      
                    </div>
                  </div>
    
                  <div class="col-md-6 col-lg-4" style="margin-bottom:3vh">
                    <div class="item">
                        <div class="info">
                           
                            <h5>Engineering Supervision</h5>
                            <p><i class="fa fa-star" aria-hidden="true"></i> Review the project quality, safety production management system and organizational management organization of the construction unit, and check the allocation of main management personnel and full-time safety ...</p>
                            <router-link to="/engineeringSupervision" style="text-decoration:none">
                              <div class="user"><i class="fa fa-arrow-circle-right"></i> View More</div>
                            </router-link>
                           
                          </div>
                      <div class="img">
                        <img src="@/assets/images/suprvison.jpeg" height="250rem" alt="">
                      </div>
                      
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4" style="margin-bottom:3vh">
                    <div class="item">
                        <div class="info">
                           
                            <h5>Project Costs</h5>
                            <p>
                              <i class="fa fa-star" aria-hidden="true"></i> Project investment decision-making stage: compiling or reviewing project investment estimates and project economic evaluation reports<br>
                              <i class="fa fa-star" aria-hidden="true"></i> Project design stage: compiling or reviewing project design estimates and construction drawing budgets

                            </p>
                            <router-link to="/projectCosts" style="text-decoration:none">
                              <div class="user"><i class="fa fa-arrow-circle-right"></i> View More</div>
                            </router-link>
                           
                          </div>
                      <div class="img">
                        <img src="@/assets/images/projectcost.jpeg" height="250rem" alt="">
                      </div>
                      
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4" style="margin-bottom:3vh">
                    <div class="item">
                        <div class="info">
                           
                            <h5>Engineering Inspection</h5>
                            <p><i class="fa fa-star" aria-hidden="true"></i> Material testing: steel bars and connectors, concrete and mortar raw materials and mix ratios, concrete and mortar test blocks, waterproof materials, decoration materials, anti-corrosion and fire-resistant coatings pipes and fittings for construction, wires...</p>
                            <router-link to="/engineeringInspection" style="text-decoration:none">
                              <div class="user"><i class="fa fa-arrow-circle-right"></i> View More</div>
                            </router-link>
                           
                          </div>
                      <div class="img">
                        <img src="@/assets/images/inspection.jpeg" height="250rem" alt="">
                      </div>
                      
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4" style="margin-bottom:3vh">
                    <div class="item">
                        <div class="info">
                           
                            <h5>Project Management</h5>
                            <p><i class="fa fa-star" aria-hidden="true"></i> Establish and improve the quality management system, urge all parties participating in the project to implement quality management personnel, institutions and systems, and ensure the sound and effective operation of the quality management system of all parties</p>
                            <router-link to="/projectManagement" style="text-decoration:none">
                              <div class="user"><i class="fa fa-arrow-circle-right"></i> View More</div>
                            </router-link>
                           
                          </div>
                      <div class="img">
                        <img src="@/assets/images/projectmanage.jpeg" height="250rem" alt="">
                      </div>
                      
                    </div>
                  </div>
              </div>
            </div>
          </section>
    </div>
  
</template>

<script>
export default {
    name:'our-expertise'

}
</script>

<style>

span:hover {
    display: inline-block;
    text-decoration: none;
    color: inherit;
}
.blog {
    width: 100%;
}

.blog img{
    max-width: 100%;
}
.blog-head {
  margin-bottom: 5vh;
  
}

.blog-head h6{
  color: #004e7f;
  position: relative;
  display: inline-block;
  text-transform: capitalize;
  font-size: 22px;
}
.blog-head h2{
    color: #F6B50E;
    font-size: 17px;
  }

.blog-head h6:after, .blog-head h6:before{
  position: absolute;
  content: "";
  width: 30px;
  height: 3px;
  background: #004e7f;
  top: 50%;
}

.blog-head h6:after{
  right: 100%;
}

.blog-head h6:before{
  left: 100%;
}

.overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
}
.blog{
  background-color: #f7f7f7;
      padding: 100px 0;
    min-height: 100vh;
}

.blog .item{
  background-color: #fff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.blog .item .more{
  position: absolute;
  right: 30px;
  bottom: 20px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  color: #004e7f;
  font-size: 19px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  transform: translate(0 , 100px);
  transition: all 0.3s ease-in-out;
}

.blog .item:hover .more{
  transform: translate(0 , 0)
}



.blog .item .img{
  /*clip-path: polygon(0 0, 100% 0, 100% 70%, 0 100%, 0 75%);*/
}

.blog .item .info{
  padding: 30px;
  position: relative;
  text-align: start;
}

.blog .item .info h5:hover{
  color: #004e7f;
}

.blog .item .info .user{
  margin-top: 20px;
  color: #F6B50E;
  font-weight: bold;
  font-size: 19px;
}

.blog .item .info .user i{
  margin-right: 5px;
  font-size: 20px;
}
  
 
 
 

</style>