<template>
  <div>
    <section class="blog-mission" data-scroll-index="4">
      <div class="container">
        <!-- header of section -->

        <!-- blog-mission items -->
        <div class="row">
          <div class="col-md-6 col-lg-4" style="margin-bottom: 3vh;">
            <div class="item">
              <div class="info">
                <h5 style="color:#004e7f">Mission</h5>
                <p>
                  CRTEC upholds the dedication of delivering superior projects,
                  manufacturing high-quality products and providing brilliant
                  services within the whole chain of construction and its
                  diversified relevant industries for continuously improving
                  traffic, people’s life and environment in general and aims at
                  helping to enhance the wellbeing and living of people for a
                  better future.
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-4" style="margin-bottom: 3vh">
            <div class="item">
              <div class="info">
                <h5 style="color:#004e7f">Vision</h5>
                <p>
                  CRTEC aims to be a leading company in international
                  construction industry Design Consultancy and Project
                  Management by improving its core advantages of working with
                  the best Chinese first-class Construction conglomerates in
                  international infrastructure development arena by enhancing
                  its international influence through globalization.
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-4" style="margin-bottom: 3vh">
            <div class="item">
              <div class="info">
                <h5 style="color:#004e7f">Our Phylosophy</h5>
                <p>
                  of "Serve the World with Integrity and Win the Future with
                  Concerted Efforts", CRTEC is willing to make friends all over
                  the world to seek common development and share bright future.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "our-expertise",
};
</script>

<style>
span:hover {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}
.blog-mission {
  width: 100%;
}

.blog-mission img {
  max-width: 100%;
}
.blog-mission-head {
  margin-bottom: 5vh;
}

.blog-head h6 {
  color: #004e7f;
  position: relative;
  display: inline-block;
  text-transform: capitalize;
  font-size: 22px;
}
.blog-head h2 {
  color: #f6b50e;
  font-size: 17px;
}

.blog-head h6:after,
.blog-head h6:before {
  position: absolute;
  content: "";
  width: 30px;
  height: 3px;
  background: #004e7f;
  top: 50%;
}

.blog-head h6:after {
  right: 100%;
}

.blog-head h6:before {
  left: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
}
.blog-mission {
  background-color: #f7f7f7;
  padding: 100px 0;
}

.blog-mission .item {
  background-color: #fff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  height: 20rem;
}

.blog-mission .item .more {
  position: absolute;
  right: 30px;
  bottom: 20px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  color: #004e7f;
  font-size: 19px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  transform: translate(0, 100px);
  transition: all 0.3s ease-in-out;
}

.blog-mission .item:hover .more {
  transform: translate(0, 0);
}

.blog-mission .item .img {
  /*clip-path: polygon(0 0, 100% 0, 100% 70%, 0 100%, 0 75%);*/
}

.blog-mission .item .info {
  padding: 30px;
  position: relative;
  text-align: start;
}

.blog-mission .item .info h5:hover {
  color: #004e7f;
}

.blog-mission .item .info .user {
  margin-top: 20px;
  color: #f6b50e;
  font-weight: bold;
  font-size: 19px;
}

.blog-mission .item .info .user i {
  margin-right: 5px;
  font-size: 20px;
}
</style>