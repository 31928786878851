import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Service from "@/views/Service";
import News from "@/views/News";
import Project from "@/views/Project";
import DetailProject from "@/views/DetailProject";
import DetailNews from "@/views/DetailNews";
import EngineeringDesign from "@/components/service/serviceDetail/EngineeringDesign";
import WholeProcessEngineeringConsulting from "@/components/service/serviceDetail/WholeProcessEngineeringConsulting";
import ProjectManagement from "@/components/service/serviceDetail/ProjectManagement";
import ProjectCosts from "@/components/service/serviceDetail/ProjectCosts";
import EngineeringSupervision from "@/components/service/serviceDetail/EngineeringSupervision";
import EngineeringInspection from "@/components/service/serviceDetail/EngineeringInspection";
import About from "@/views/About";
import ContactUs from "@/views/ContactUS";
Vue.use(VueRouter);

const routes = [
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/contactus",
    name: "ContactUs",
    component: ContactUs,
  },
  {
    path: "/service",
    name: "Service",
    component: Service,
  },
  {
    path: "/news",
    name: "News",
    component: News,
  },
  {
    path: "/project",
    name: "Project",
    component: Project,
  },
  {
    path: "/detailProject",
    name: "DetailProject",
    component: DetailProject,
  },
  {
    path: "/detailNews",
    name: "DetailNews",
    component: DetailNews,
  },
  {
    path: "/engineeringDesign",
    name: "EngineeringDesign",
    component: EngineeringDesign,
  },
  {
    path: "/wholeProcessEngineeringConsulting",
    name: "WholeProcessEngineeringConsulting",
    component: WholeProcessEngineeringConsulting,
  },
  {
    path: "/projectManagement",
    name: "ProjectManagement",
    component: ProjectManagement,
  },
  {
    path: "/projectCosts",
    name: "ProjectCosts",
    component: ProjectCosts,
  },
  {
    path: "/engineeringSupervision",
    name: "EngineeringSupervision",
    component: EngineeringSupervision,
  },
  {
    path: "/engineeringInspection",
    name: "EngineeringInspection",
    component: EngineeringInspection,
  },
  {
    path: "/scitechValley",
    beforeEnter() {
      location.href = "https://www.scitechvalley.com";
    },
  },
  {
    path: "/facebook",
    beforeEnter() {
      location.href = "https://www.facebook.com/profile.php?id=61550066237138";
    },
  },
  {
    path: "/linkedin",
    beforeEnter() {
      location.href = "https://www.linkedin.com/company/crtec/";
    },
  },
];

const router = new VueRouter({
  routes,
});

export default router;
