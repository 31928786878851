<template>
  <div>
      <Navbar/>
      <Landing/>
      <WhoWe/>
      <MissionVision/>

      <Footer/>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import Landing from '@/components/about/Landing.vue';
import Footer from '@/components/Footer.vue';
import MissionVision from '@/components/about/MissionVision.vue';
import WhoWe from '@/components/about/WhoWe.vue';
  export default {
      name:'home-page',
      data(){
        return{
          
        }

      },
      components:{
          Navbar,
          Landing,
          MissionVision,
          Footer,
          WhoWe
      }
      
  }
</script>

<style lang="scss" scoped>

</style>