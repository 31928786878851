<template>
    <div>
        <Navbar/>
        <Landing/>
        <NewsList/>
        <Footer/>

    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import Landing from '@/components/news/Landing.vue'
import NewsList from '@/components/news/NewsList.vue'
import Footer from '@/components/Footer.vue';

    export default {
        name:'news-page',
        components:{
            Landing,
            NewsList,
            Footer,
            Navbar
        }
        
    }
</script>

<style lang="scss" scoped>

</style>