<template>
    <div class="wrapper">
      <div class="headeer">
        <!--Content before waves-->
        <div class="inner-headerrr flex">
          <!--Just the logo.. Don't mind this-->
          
          <div class="services-home">
            <h2><span>High Quality Design,<br> High Quality Structural  Development, <br>High Quality Finishing</span></h2>
            <div class="headinggg-home text-center">
                    
              <p>
                We are not going to compromise quality and that’s what we are known for. High Quality Consultation, High Quality Design, High Quality Development, High Quality Finishing and High Quality Supervision.                  
                 
              </p>
          </div>
         
          <!-- <button><i class="fa-solid fa-circle-right"></i>Contact Us</button> -->
      </div>
          
        </div>
  
        
      </div>
      <!--Header ends-->
  
      
    </div>
  </template>
  
  <script>
  export default {
    name: "Landing-page",
    components:{
    }
  };
  </script>
  
  <style>
  
  
  h1 {
    font-family: 'Lato', sans-serif;
    font-weight:300;
    letter-spacing: 2px;
    font-size:48px;
  }
  p {
    font-family: 'Lato', sans-serif;
    letter-spacing: 1px;
    font-size:14px;
    color: #333333;
  }
  .wrapper{
    background: #f7f7f7;
  }
  .headeer {
    position:relative;
    text-align:center;
    background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), #6acde4), url('@/assets/images/2im.jpeg');
    color:white;
    background-position: 100%;
   
  }
  .logo {
    width:50px;
    fill:white;
    padding-right:15px;
    display:inline-block;
    vertical-align: middle;
  }
  
  .inner-headerrr {
    height:100%;
    
    width:100%;
    margin: 0;
    padding: 0;
  }
  .services-service{
      display: inline-block;
      margin-right: auto; 
      margin-left: 5%;
      
      
  }
  .services-service h5 {
      font-size: 22px;
      text-align: start;
  }
  .services-service h2 {
    font-size: 22px;
    color: #1f7589;
    margin-top: 100px;
    margin-bottom: 40px;
    text-align: start;
  }
  .services-service p {
    font-size: 15px;
    color: #fff;
    text-align: start;
  }
  .services-service h5 span{
      color: #6acde4;
      font-size: 22px;
  }
  .services-service h2 span{
    color: #fff;
    font-size: 32px;
  }
  .services-service button{
      border: none;
      border-radius: 5px;
      background-color:#FAD605;
      z-index: -1;
      width: 30%;
      height: 40px;
       float: left;
      color: #fff;
  }
  .services-service button:hover {
      
      background-color: #6acde4;
      color: #000;
  }
  
  .flex { /*Flexbox for containers*/
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  /*Shrinking for mobile*/
  @media (max-width: 768px) {
    .waves {
      height:40px;
      min-height:40px;
    }
    .content {
      height:30vh;
    }
    h1 {
      font-size:24px;
    }
  }
  .service-description{
    color: #000;
  }
  .headinggg-service {
    font-size: 20px;
    font-weight: 700;
    margin: 0px;
    padding: 0;
    color: #fff;
  
  }
   .headinggg-service p {
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    margin: 10px  10px 20px;
    padding: 0;
  }
  .services-home{
    display: inline-block;
    align-items: start;
    width: 100%;
    margin-right: auto; 
   margin-left: 5%;
      
  }
  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    .services-home{
      width: 60%;     
  }
  }
  
  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
    .services-home{
      width: 60%;   
  }
  }
  .services-home h5 {
    font-size: 22px;
    text-align: start;
  }
  .services-home h2 {
  font-size: 22px;
  color: #1f7589;
  margin-top: 100px;
  margin-bottom: 40px;
  text-align: start;
  font-weight: bold;
  }
  .services-home p {
  font-size: 13px;
  color: #fff;
  text-align: start;
  }
  .services-home h5 span{
    color: #6acde4;
    font-size: 22px;
    text-align: start;
  }
  .services-home h2 span{
  color: #fff;
  font-size: 42px;
  text-align: start;
  }
  .headinggg-home {
  font-weight: 700;
  margin: 0px;
  padding: 0;
  color: #fff;
  
  }
  .headinggg-home p {
  font-size: 15px;
  color: #fff;
  margin: 10px  10px 20px;
  padding: 0;
  }
  
  
  </style>