<template>
  <div>
    <Navbar />
    <div class="wrapper">
      <div class="headeer">
        <div class="inner-headerrr flex">
          <div class="services-home">
            <h2>
              <span>Engineering Supervision</span>
            </h2>
            <div class="headinggg-home text-center">
              <p>
                High-quality products, outstanding and outstanding, are the
                proof of the comprehensive strength of the enterprise and the
                embodiment of the core competitiveness of the enterprise. CRTEC
                company creates performance with high-quality products and
                creates brilliance with high-quality products.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="services-cul">
      <div class="headerr active">
        <h1><strong>Engineering Supervision</strong></h1>
        <!-- <h5>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has,</h5> -->
        <div class="cache"></div>
      </div>
      <div class="container">
        <div class="row" style="margin-left:5%">
          <div class="col-md-6">
            <carousel
              class="carousel-project"
              :per-page="1"
              :mouse-drag="false"
              v-bind:autoplay="true"
              v-bind:loop="true"
            >
              <slide>
                <div class="card">
                  <div class="card-img">
                    <img src="@/assets/images/supre2.jpeg" />
                  </div>
                </div>
              </slide>
              <slide>
                <div class="card">
                  <div class="card-img">
                    <img src="@/assets/images/suprvison.jpeg" />
                  </div>
                </div>
              </slide>
              
            </carousel>
          </div>
          <div class="col-md-6">
            <div class="item">
              <div class="info">
                <!-- <h5>Engineering Supervision</h5> -->
                <p style="font-size: 15px">
                  <i class="fa fa-star" style="color:#fad605" aria-hidden="true"></i>Review the
                  project quality, safety production management system and
                  organizational management organization of the construction
                  unit, and check the allocation of main management personnel
                  and full-time safety production management personnel of the
                  construction unit; Review the construction organization design
                  and construction plan and special construction plan, and
                  supervise the construction unit to implement construction
                  Drawing design documents and project construction standards,
                  and organizing construction according to the approved
                  construction organization design and construction plan and
                  special construction plan
                  <br />
                  <i class="fa fa-star" style="color:#fad605" aria-hidden="true"></i>Review the
                  project materials, components and equipment quality
                  certification documents submitted by the construction unit,
                  and review the materials and components used in the project
                  Carry out witness sampling for inspection
                  <br />
                  <i class="fa fa-star" style="color:#fad605" aria-hidden="true"></i>Implement process
                  control of project quality by means of inspection, side station,
                  parallel inspection, etc., conduct inspection and acceptance of
                  concealed projects, sub-projects, sub-projects and unit projects,
                  and sign acceptance opinions in the corresponding inspection
                  documents Review the construction progress plan, check the
                  implementation of the progress plan at each stage, and coordinate
                  construction progress issues through regular supervision meetings,
                  etc
      
                  <br />
                  <i class="fa fa-star" style="color:#fad605" aria-hidden="true"></i>Review the application
                  for payment of project progress payment and application for project
                  change and claim, and coordinate and handle construction progress
                  adjustments, cost claims, and contract disputes etc
      
                  <br />
                  <i class="fa fa-star" style="color:#fad605" aria-hidden="true"></i>Review the completion
                  acceptance and settlement application submitted by the construction
                  unit, prepare the project quality assessment report and participate
                  in the completion inspection of the project
      
                  <br />
                  <i class="fa fa-star" style="color:#fad605" aria-hidden="true"></i>Provide both project
                  supervision services and project management services, and provide
                  integrated services for project supervision and project management
      
                  <br />
                </p>
                <!-- <div class="user"><i class="fa fa-arrow-circle-right"></i>Status: <p class="subtitle">Completed</p></div>
                    <div class="user"><i class="fa fa-arrow-circle-right"></i>Started: <p class="subtitle">Completed</p></div>
                    <div class="user"><i class="fa fa-arrow-circle-right"></i>Completed: <p class="subtitle">Completed</p></div>
                    <div class="user"><i class="fa fa-arrow-circle-right"></i>Cost: <p class="subtitle">Completed</p></div> -->
              </div>
            </div>
          </div>
          
        </div>
      </div>
    
    </div>
    <Footer/>
  </div>
</template>
  
  <script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Landing-page",
  components: {
    Navbar,
    Footer
  },
};
</script>
  
  <style>
h1 {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  letter-spacing: 2px;
  font-size: 48px;
}
p {
  font-family: "Lato", sans-serif;
  letter-spacing: 1px;
  font-size: 14px;
  color: #333333;
}

.headeer {
  position: relative;
  text-align: center;
  background-image: linear-gradient(
      to bottom,
      rgba(245, 246, 252, 0.52),
      #6acde4
    ),
    url("@/assets/images/2im.jpeg");
  color: white;
  background-position: 100%;
}
.logo {
  width: 50px;
  fill: white;
  padding-right: 15px;
  display: inline-block;
  vertical-align: middle;
}

.inner-headerrr {
  height: 55vh;
  width: 100%;
  margin: 0;
  padding: 0;
}
.services-service {
  display: inline-block;
  margin-right: auto;
  margin-left: 5%;
}
.services-service h5 {
  font-size: 22px;
  text-align: start;
}
.services-service h2 {
  font-size: 22px;
  color: #1f7589;
  margin-top: 100px;
  margin-bottom: 40px;
  text-align: start;
}
.services-service p {
  font-size: 15px;
  color: #fff;
  text-align: start;
}
.services-service h5 span {
  color: #6acde4;
  font-size: 22px;
}
.services-service h2 span {
  color: #fff;
  font-size: 32px;
}
.services-service button {
  border: none;
  border-radius: 5px;
  background-color: #fad605;
  z-index: -1;
  width: 30%;
  height: 40px;
  float: left;
  color: #fff;
}
.services-service button:hover {
  background-color: #6acde4;
  color: #000;
}

.flex {
  /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }
  .content {
    height: 30vh;
  }
  h1 {
    font-size: 24px;
  }
}
.service-description {
  color: #000;
}
.headinggg-service {
  font-size: 20px;
  font-weight: 700;
  margin: 0px;
  padding: 0;
  color: #fff;
}
.headinggg-service p {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  margin: 10px 10px 20px;
  padding: 0;
}
.services-home {
  display: inline-block;
  align-items: start;
  width: 100%;
  margin-right: auto;
  margin-left: 5%;
}
.services-home h5 {
  font-size: 22px;
  text-align: start;
}
.services-home h2 {
  font-size: 22px;
  color: #1f7589;
  margin-top: 100px;
  margin-bottom: 40px;
  text-align: start;
  font-weight: bold;
}
.services-home p {
  font-size: 13px;
  color: #fff;
  text-align: start;
}
.services-home h5 span {
  color: #6acde4;
  font-size: 22px;
  text-align: start;
}
.services-home h2 span {
  color: #fff;
  font-size: 42px;
  text-align: start;
}
.headinggg-home {
  font-weight: 700;
  margin: 0px;
  padding: 0;
  color: #fff;
}
.headinggg-home p {
  font-size: 15px;
  color: #fff;
  margin: 10px 10px 20px;
  padding: 0;
}
.services-cul {
  padding: 30px 0px 0px 0px;
  width: 100%;
  margin-left: -3vh;
}

.headerr {
  position: relative;
  left: 55%;
  margin-top: 10vh;
  margin-bottom: 1vh;

  transform: translate(-50%, -50%);
  color: #004e7f;
  font-family: Helvetica;
  font-size: 2.5vw;
}
.headerr .cache {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.8s ease-out 0s;
}
.headerr.active .cache {
  transition: all 0.8s ease-out 0.3s;
  left: -100%;
}
.cache:before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 1%;
  height: 0;
  transition: all 0.3s ease-out 0.8s;
  background-color: #fad605;
}
.headerr.active .cache:before {
  height: 100%;
  transition: all 0.3s ease-out 0s;
}
.headerr h1 {
  font-weight: 10;
  margin: 0;
  font-size: 20px;
  text-align: start;
  padding: 5px 0px 5px 10px;
}
.headerr h5 {
  margin: 0;
  color: gray;
  font-size: 15px;
  text-align: start;
  padding: 0px 0px 0px 10px;
}

.card {
  width: 20rem;
  display: flex;
  gap: 1rem;
  border: none;
}

.card-img img {
  display: flex;
  height: 30rem;
  width: 100vh;
}

.blog .item {
  background-color: #fff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.blog .item .more {
  position: absolute;
  right: 0px;
  bottom: 20px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  color: #004e7f;
  font-size: 19px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  transform: translate(0, 100px);
  transition: all 0.3s ease-in-out;
}

.blog .item:hover .more {
  transform: translate(0, 0);
}
.item .img {
  /*clip-path: polygon(0 0, 100% 0, 100% 70%, 0 100%, 0 75%);*/
}

.item .info {
  padding: 5px;
  position: relative;
  text-align: start;
}

.item .info h5:hover {
  color: #004e7f;
}
.subtitle {
  color: #004e7f;
  padding: 5px;
}

.item .info .user {
  margin-top: 20px;
  color: #f6b50e;
  font-weight: bold;
  font-size: 19px;
  display: flex;
}

.item .info .user i {
  margin-right: 5px;
  font-size: 20px;
  padding: 5px;
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .services-home{
    width: 50%;     
}
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .services-home{
    width: 50%;   
}
}
</style>