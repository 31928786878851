<template>
  <div >
      <section class="blog" data-scroll-index="4">
          <div class="container">
            <!-- header of section -->
            <div class="blog-head text-center">
              <h2>Consulting More! Doing More! </h2>
              <h6> Collaborating More! <br>Designing More!</h6>
            </div>
            <div class="headerr active">
              <h1><strong>Latest</strong> News</h1>
                  <h5>Find out how we bring ideas to life, keep up to date with industry news and our insights on pioneering the innovations of the future</h5>
              <div class="cache"></div>
            </div>
            <!-- blog items -->
            <div class="row" >
              <Loader v-if="!newsList" />
              <div class="col-md-6 col-lg-4" style="margin-bottom:3vh" v-for="(news, index) in newsList" :key="index" >
                <div class="item">
                  <div class="img">
                    <img v-bind:src="news.news_cover.original_url" height="250rem" width="100%" alt="">
                  </div>
                  <div class="info">
                    <p class="date-info">{{news.created_at }}</p>
                     <h5>{{news.title }}</h5>
                      <p>{{ news.body.substring(0,200)+".." }}</p>
                      <div class="user" @click=" $router.push({ name: 'DetailNews', params: { id: news.id }, })"><i class="fa fa-arrow-circle-right"></i> Read More</div>
                    </div>
                  
                  
                </div>
              </div>
            </div>
          </div>
        </section>
  </div>

</template>

<script>
import Loader from '../Loader.vue'
export default {
  name:'our-expertise',
  data(){
        return{
            newsList:null
        }
    },
  mounted() {
    
    this.allNews()
   
  },
  components:{
    Loader

  },
  methods:{
        allNews() {
        this.$api.request({ auth: false })
          .get("/news")
          .then((response) => { this.newsList = response.data.data })
          .catch((error) => console.log(error));
    },
  }

}
</script>

<style>

span:hover {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}
.blog {
  width: 100%;
}

.blog img{
  max-width: 100%;
}
.blog-head {
margin-bottom: 5vh;

}

.blog-head h6{
color: #004e7f;
position: relative;
display: inline-block;
text-transform: capitalize;
font-size: 22px;
}
.blog-head h2{
  color: #F6B50E;
  font-size: 17px;
}

.blog-head h6:after, .blog-head h6:before{
position: absolute;
content: "";
width: 30px;
height: 3px;
background: #004e7f;
top: 50%;
}

.blog-head h6:after{
right: 100%;
}

.blog-head h6:before{
left: 100%;
}

.overlay{
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
z-index: 0;
}
.blog{
background-color: #f7f7f7;
    padding: 100px 0;
  min-height: 100vh;
}

.blog .item{
background-color: #fff;
box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
border-radius: 10px;
overflow: hidden;
position: relative;
}

.blog .item .more{
position: absolute;
right: 30px;
bottom: 20px;
box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
color: #004e7f;
font-size: 19px;
width: 40px;
height: 40px;
border-radius: 50%;
line-height: 40px;
text-align: center;
transform: translate(0 , 100px);
transition: all 0.3s ease-in-out;
}

.blog .item:hover .more{
transform: translate(0 , 0)
}



.blog .item .img{
/*clip-path: polygon(0 0, 100% 0, 100% 70%, 0 100%, 0 75%);*/
}

.blog .item .info{
padding: 30px;
position: relative;
text-align: start;
}
.date-info{
  font-size: 15px;
  color: #F6B50E;
  font-weight: bold;
}

.blog .item .info h5:hover{
color: #004e7f;
}

.blog .item .info .user{
margin-top: 20px;
color: #F6B50E;
font-weight: bold;
font-size: 19px;
}

.blog .item .info .user i{
margin-right: 5px;
font-size: 20px;
}
.headerr{
  position: relative;
  left: 52%;
  margin-top: 10vh;
  margin-bottom: 1vh;
    
  transform: translate(-50%, -50%);
  color: #004e7f;
  font-family: Helvetica;
  font-size: 2.5vw;
    
}
.headerr .cache{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all .8s ease-out 0s;
}
.headerr.active .cache{
  transition: all .8s ease-out .3s;
  left: -100%;
}
.cache:before{
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 1%;
  height: 0;
  transition: all .3s ease-out .8s;
  background-color: #FAD605
}
.headerr.active .cache:before{
  height: 100%;
  transition: all .3s ease-out 0s;
}
.headerr h1{
  font-weight: 10;
  margin: 0;
  font-size: 20px;
  text-align: start;
  padding: 5px 0px 5px 10px;
}
.headerr h5{
  margin: 0;
    color: gray;
    font-size: 15px;
    text-align: start;
    padding: 0px 0px 0px 10px;
}





</style>