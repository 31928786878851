<template>
    <div>
        <Navbar/>
        <Landing/>
        <ServiceList/>
        <Footer/>

    </div>
</template>

<script>
import Landing from '@/components/service/Landing.vue'
import Navbar from '@/components/Navbar.vue';
import ServiceList from '@/components/service/ServiceList.vue'
import Footer from '@/components/Footer.vue';

export default {
    name:'service-page',
    components:{
        Landing,
        Navbar,
        ServiceList,
        Footer
    }
    
}
</script>

<style lang="scss" scoped>

</style>