<template>
  <div class="who-main">
    <div class="blog-head-about text-center" style="padding-top: 20px">
      <h2>About Us</h2>
      <h6>Chines & International Engineers & Architects</h6>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="blog-head-about text-start">
            <h2>Who We Are</h2>
          </div>
          <div class="item">
            <div class="info">
              <p>
                China Rail Transit Engineering Consultancy was founded in 1999
                in China, Sichuan Province, Chengdu City and has also
                registration in Ethiopia made in 2018. Our Chengdu head-office
                is the Headquarter of the group and all our projects in China,
                Asia and Asia Pacific Region are lead from here and our Addis
                Ababa office is from where all our projects on the African
                continent are lead from. Through our parent company in China we
                had achieved the comprehensive qualification of project
                supervision, cost consulting grade A qualification, highway
                engineering supervision grade A qualification of the Ministry of
                Communications, water conservancy project supervision grade A
                qualification, engineering testing qualification, engineering
                design qualification, Sichuan construction project management
                and whole process engineering consulting enterprise industry
                grade A certification.<br><br>
                In Ethiopia we have achieved Grade One
                Qualification for Building and Road and Highway, Bridges and
                Railway Projects. We have a strong and professional technical
                team and gathered more than 3,000 engineering management
                talents, including more than 2,700 registered personnel at the
                provincial and national levels in China, Asia and Africa.
                Business scopes cover planning, consultancy & management, survey
                & design, supervision for engineering sectors such as railway,
                urban rail transit, highway, road, Architecture, municipal
                works, port terminal, civil aviation airport and other fields.
                To provide high-standard, professional and comprehensive
                engineering design and consultancy & management services to Asia
                and Africa as well as by integrating international innovative
                concepts and latest technologies. In Asia, CRTEC had
                accomplished several landmark design and project supervision and
                management works with outstanding quality. Our African
                Headquarter in Addis Ababa is lead with collaborative African
                and Chinese management team making us the first Chinese company
                to embrace African spirits and elements in our engineering
                designs and project management techniques. In Africa, CRTEC has
                set up a Pan-African network system where our 500+ people
                collaborate across this network from 5 offices across two
                continents by being an exemplary global designer, architect,
                engineer, consultant and planner understanding Africa’s demands
                most. With the mission of "SERVING THE PEOPLE, SERVING THE
                WORLD", CRTEC is destined to be an internationally renowned
                Intellectual Infrastructure Planning, Engineering Design and
                Project Management Service Company.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6" >
          <carousel
            class="carousel-project"
            :per-page="1"
            :mouse-drag="false"
            v-bind:autoplay="true"
            v-bind:loop="true"
          >
            <slide>
              <div class="card">
                <div class="card-img">
                  <img src="@/assets/images/about-new.jpg" />
                </div>
              </div>
            </slide>
            <slide>
              <div class="card">
                <div class="card-img">
                  <img width="100%" src="@/assets/images/about-new2.jpg" />
                </div>
              </div>
            </slide>
            <!-- <slide>
              <div class="card">
                <div class="card-img">
                  <img src="@/assets/images/about3.jpeg" />
                </div>
              </div>
            </slide> -->
          </carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.blog-head-about {
}
.card-img img {
  width: 60vh;
}

.blog-head-about h6 {
  color: #004e7f;
  position: relative;
  display: inline-block;
  text-transform: capitalize;
  font-size: 22px;
  padding-bottom: 3%;
}
.blog-head-about h2 {
  color: #f6b50e;
  font-size: 17px;
  padding-top: 3%;
}

.who-main {
  background-color: #f7f7f7;
}
</style>