<template>
    <div>
      <div class="wrapper">
        <div class="headeer-home">
          <!--Content before waves-->
          <div class="inner-headerrr flex">
            <!--Just the logo.. Don't mind this-->
            
           <div class="services-home">
              <h2><span>SERVING THE PEOPLE, SERVING THE WORLD!</span></h2>
              <h2 class="china" style=" margin-top:-5%"><span>China Rail Transit Engineering Consultancy (CRTEC)</span></h2>

              <div class="headinggg-home text-center">
                      
                <p>High-quality products, outstanding and outstanding, are the proof of the comprehensive strength of the enterprise and the embodiment of the core competitiveness of the enterprise. CRTEC company creates performance with high-quality infrastractures and creates brilliance with high-quality engineering.
   
                </p>
            </div>
            <div class="landing-button ">
              <router-link to="/about"> <button class="button-about"><i class="fa fa-arrow-circle-right"></i> More About Us</button></router-link>
             <router-link to="/service"> <button class="button-service"><i class="fa fa-arrow-circle-right"></i>Our Services</button></router-link>
            </div>
            <!-- <button><i class="fa-solid fa-circle-right"></i>Contact Us</button> -->
        </div>
            
          </div>
    
          
        </div>
        </div>
        <!-- <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-indicators">
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="@/assets/images/3im.jpeg" class="d-block  w-100" alt="...">
                <div class="carousel-caption d-none d-md-block">
                  <h5>First slide label</h5>
                  <p>Some representative placeholder content for the first slide.</p>
                </div>
              </div>
              <div class="carousel-item">
                <img src="@/assets/images/1im.jpeg" class="d-block w-100" alt="...">
                <div class="carousel-caption d-none d-md-block">
                  <h5>Second slide label</h5>
                  <p>Some representative placeholder content for the second slide.</p>
                </div>
              </div>
              <div class="carousel-item">
                <img src="@/assets/images/2im.jpeg" class="d-block w-100" alt="...">
                <div class="carousel-caption d-none d-md-block">
                  <h5>Third slide label</h5>
                  <p>Some representative placeholder content for the third slide.</p>
                </div>
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div> -->

    </div>
</template>

<script>
  // import $ from "jquery";
export default {
  name: "CarouselPAge",
  mounted() {
    // $(".carousel").carousel({
    //   interval: 2200,
    // });
  },
};
</script>

<style lang="scss" scoped>
.carousel-item img{
    height: 75vh;
}
.carousel-inner{
    background-color: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), #6acde4);


}


.headeer-home {
  position:relative;
  text-align:center;
  background-image:linear-gradient(to bottom, rgba(245, 246, 252, 0.12), #8cc4d1), url('@/assets/images/3im.jpeg');
  background-position: center;
background-repeat: no-repeat;
background-size: cover;
  color:white;
  background-position: 100%;
 
}
.logo {
  width:50px;
  fill:white;
  padding-right:15px;
  display:inline-block;
  vertical-align: middle;
}

.inner-headerrr {
  height:100%;
  width:100%;
  margin: 0;
  padding: 0;
  padding-bottom: 30px;
}
.services-home{
    display: inline-block;
    align-items: start;
    width: 100%;
    margin-right: auto; 
   margin-left: 5%;
      
}
.services-home .china span {
    font-size: 25px;
    text-align: start;
    color: #FAD605;
    margin-top: 5%;
    font-weight: bold;
   

}
.services-home h2 {
  font-size: 22px;
  color: #1f7589;
  margin-top: 100px;
  margin-bottom: 40px;
  text-align: start;
  font-weight: bold;
}
.services-home p {
  font-size: 13px;
  color: #fff;
  text-align: start;
}
.services-home h5 span{
    color: #6acde4;
    font-size: 22px;
    text-align: start;
}
.services-home h2 span{
  color: #fff;
  font-size: 42px;
  text-align: start;
}
.services-home .button-about{
    border: none;
    border-radius: 10px;
    background-color:#FAD605;
    z-index: -1;
    width: 30%;
    height: 100%;
    float: left;
    color: #fff;
    padding: 2%;
}
.services-home .button-service{
  border: none;
  margin-left: 10px;
  border-radius: 10px;
  background-color:#fff;
  z-index: -1;
  width: 30%;
  height: 100%;
  float: left;
  color: #000;
  padding: 2%;
}
.services-home .button-service i{
  
  color: #F6B50E;
  font-weight: bold;
  margin-right: 5px;
}

.services-home .button-service i{
}
.services-home button:hover {
    
    background-color: #6acde4;
    color: #000;
}

.flex { /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height:40px;
    min-height:40px;
  }
  .content {
    height:30vh;
  }
  h1 {
    font-size:24px;
  }
}
.service-description{
  color: #000;
}
.headinggg-home {
  font-weight: 700;
  margin: 0px;
  padding: 0;
  color: #fff;

}
 .headinggg-home p {
  font-size: 15px;
  color: #fff;
  margin: 10px  10px 20px;
  padding: 0;
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .services-home{
    width: 50%;     
}
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .services-home{
    width: 50%;   
}
}


</style>