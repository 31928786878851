<template>
  
    <div class="main-header">
      <div class="headerr active">
        <h1>Our <strong>Projects</strong> </h1>
            <h5>CRTEC people perform their duties conscientiously, act proactively, and strictly control the project activities, which not only promote the smooth progress of the project construction, but also ensure the quality and safety of the project.</h5>
        <div class="cache"></div>
      </div>
      <Loader v-if="!projectList" />
        <carousel class="carousel-project" :per-page="1" :mouse-drag="false" v-bind:autoplay="true" v-bind:loop="true">
            <slide v-for="(project, index) in projectList" :key="index">
                <div class='card'>
                    <div class='card-img'>
                        <img v-bind:src="project.project_image.original_url"/>
                        <span class='card-project'>
                            <strong>{{project.title.substring(0,100)+".." }}</strong>
                            <!-- <p>{{ project.description.substring(0,100)+".." }} </p> -->
                            <div class="user" style="color:#f8e05a; font-weight:bold" @click="$router.push({ name: 'DetailProject', params: { id: project.id }, })"><i class="fa fa-arrow-circle-right"></i> Read More</div>
                            <!-- <small>orem Ipsum is simply dummy text of the printing and typesetting industry. orem Ipsum is simply dummy text of the printing and typesetting industry. orem Ipsum is simply dummy text of the printing and typesetting industry. orem Ipsum is simply dummy text of the printing and typesetting industry.</small> -->
                          </span>
                    </div>
                  </div>
            </slide>
            
          </carousel>
        

    </div>
</template>

<script>
import Loader from './Loader.vue';
export default {
  name: "project-page",
  data(){
    return{
      projectList:null

    }
  },
  components:{
    Loader

  },
  mounted() {
    
    this.allProject()
   
  },
  methods:{
    allProject() {
        this.$api.request({ auth: false })
          .get("/projects")
          .then((response) => { this.projectList = response.data.data })
          .catch((error) => console.log(error));
    },
  }
};
</script>

<style lang="scss" scoped>
.main-header{
  width: 100%;
  margin-left: 1%;
}
.carousel-project{
  margin-left: 4%;
}
.card {
    width: 20rem;
    display: flex;
    gap: 1rem;
    border: none;
  }
  
  .card-img img{
    display: flex;
    height: 30rem;

  }
  .card-project {
    padding: 10px 0px 0px 10px;
    color: #9397ad;
    text-align: start;
    margin-top: -40%;
    position: relative;
    height: 20vh;
    display: flex;
    flex-direction: column;
    background: rgba(188, 186, 186, 0.9);
    width: 76vh;
  }
 
 
  .card-project {
   
  }
  
  .card-project strong {
    color: #004e7f;
    font-size: 18px;
    font-weight: bold;
    margin-right: 5%;
    width: 50%;

  }
  .card-project small {
    color: #000;
    font-size: 15px;
    width: 70%;
  }
  .headerr{
    position: relative;
    left: 55%;
    margin-top: 10vh;
    margin-bottom: 1vh;
      
    transform: translate(-50%, -50%);
    color: #004e7f;
    font-family: Helvetica;
    font-size: 2.5vw;
      
  }
  .headerr .cache{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all .8s ease-out 0s;
  }
  .headerr.active .cache{
    transition: all .8s ease-out .3s;
    left: -100%;
  }
  .cache:before{
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 2%;
    height: 0;
    transition: all .3s ease-out .8s;
    background-color: #FAD605
  }
  .headerr.active .cache:before{
    height: 100%;
    transition: all .3s ease-out 0s;
  }
  .headerr h1{
    font-weight: 10;
    margin: 0;
    font-size: 20px;
    text-align: start;
    padding: 5px 0px 5px 10px;
  }
  .headerr h5{
    margin: 0;
      color: gray;
      font-size: 15px;
      text-align: start;
      padding: 0px 0px 0px 10px;
  }
  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    .card-project {
      padding: 10px 0px 0px 10px;
      color: #9397ad;
      text-align: start;
      margin-top: -40%;
      position: relative;
      height: 20vh;
      display: flex;
      flex-direction: column;
      background: rgba(188, 186, 186, 0.9);
      width: 76vh;
    }
    .card-project strong {
      color: #004e7f;
      font-size: 18px;
      font-weight: bold;
      margin-right: 5%;
      width: 80%;
  
    }
  }
  
  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
    .card-project {
      padding: 10px 0px 0px 10px;
      color: #9397ad;
      text-align: start;
      margin-top: -40%;
      position: relative;
      height: 20vh;
      display: flex;
      flex-direction: column;
      background: rgba(188, 186, 186, 0.9);
      width: 76vh;
    }
   
    .card-project strong {
      color: #004e7f;
      font-size: 18px;
      font-weight: bold;
      margin-right: 5%;
      width: 80%;
  
    }
  }
 


</style>