<template>
    <div class="content-103" style="margin-top: 5%">
        <div class="headerr-service active">
            <h1>Our <strong>Service</strong> </h1>
                <h5 style="width:80%; ">The company adheres to the concept of "casting high-quality infrastractures, setting up monuments, work to reassure owners, and service to satisfy owners", and adheres to the practice principles of "fairness, independence, integrity, and science".</h5>
            <div class="cache"></div>
          </div>
        <div class="container">
            <div class="row" style="margin-left: -6vh;">
              
                <div class="col-md-4 col-sm-4" >
                  <router-link to="/engineeringDesign" style="text-decoration:none">
                    <div class="service-post">
                        <figure class="snip1107 blue"><img src="@/assets/images/design.jpeg" height="250rem" width="100%"  alt="sampl31"/>
                            <figcaption>
                              <div><span>
                                </span></div>
                              <div>
                                <h3>Engineering Design</h3>
                              </div>
                            </figcaption>
                          </figure>
                        <!-- service-content -->
                        <div class="service-hover"></div>
                    </div>
                  </router-link>
                </div>
                <div class="col-md-4 col-sm-4">
                  <router-link to="/wholeProcessEngineeringConsulting" style="text-decoration:none">
                    <div class="service-post">
                        <figure class="snip1107 blue"><img src="@/assets/images/wholeprocess.jpeg" height="250rem" width="100%"  alt="sampl31"/>
                            <figcaption>
                              <div><span></span></div>
                              <div>
                                <h3>Whole Process Engineering Consulting</h3>
                              </div>
                            </figcaption>
                          </figure>
                        <!-- service-content -->
                        <div class="service-hover"></div>
                    </div>
                    </router-link>
                </div>
                <div class="col-md-4 col-sm-4">
                  <router-link to="/engineeringSupervision" style="text-decoration:none">
                    <div class="service-post">
                        <figure class="snip1107 blue"><img src="@/assets/images/suprvison.jpeg" height="250rem" width="100%" alt="sampl31"/>
                            <figcaption>
                              <div><span></span></div>
                              <div>
                                <h3>Engineering Supervision
                                </h3>
                              </div>
                            </figcaption>
                          </figure>
                        <!-- service-content -->
                        <div class="service-hover"></div>
                    </div>
                  </router-link>
                </div>
                <div class="col-md-4 col-sm-4">
                  <router-link to="/projectCosts" style="text-decoration:none">
                    <div class="service-post">
                        <figure class="snip1107 blue"><img src="@/assets/images/projectcost.jpeg" height="250rem" width="100%"  alt="sampl31"/>
                            <figcaption>
                              <div><span></span></div>
                              <div>
                                <h3>Project Costs</h3>
                              </div>
                            </figcaption>
                          </figure>
                        <!-- service-content -->
                        <div class="service-hover"></div>
                    </div>
                  </router-link>
                </div>
                <div class="col-md-4 col-sm-4">
                  <router-link to="/engineeringInspection" style="text-decoration:none">
                    <div class="service-post">
                        <figure class="snip1107 blue"><img src="@/assets/images/inspection.jpeg" height="250rem" width="100%"  alt="sampl31"/>
                            <figcaption>
                              <div><span></span></div>
                              <div>
                                <h3>Engineering Inspection</h3>
                              </div>
                            </figcaption>
                          </figure>
                        <!-- service-content -->
                        <div class="service-hover"></div>
                    </div>
                  </router-link>
                </div>
                <div class="col-md-4 col-sm-4">
                  <router-link to="/projectManagement" style="text-decoration:none">
                    <div class="service-post">
                        <figure class="snip1107 blue"><img src="@/assets/images/projectmanage.jpeg" height="250rem" width="100%"  alt="sampl31"/>
                            <figcaption>
                              <div><span></span></div>
                              <div>
                                <h3>Project Management</h3>
                              </div>
                            </figcaption>
                          </figure>
                        <!-- service-content -->
                        <div class="service-hover"></div>
                    </div>
                  </router-link>
                </div>
            </div>
        </div>
    </div>
  
</template>

<script>
export default {
    name:'our-expertise'

}
</script>

<style>

  .content-103{
      padding-bottom:50px;
    
  }
 
  .col-md-4 {
    margin-bottom: 10px;
    
  }
  
 
  .headerr-service{
    position: relative;
    left: 50%;
    margin-top: 10vh;
    margin-bottom: 1vh;
    transform: translate(-50%, -50%);
    color: #004e7f;
    font-family: Helvetica;
    font-size: 2.5vw;
    width: 90%;
      
  }
  .headerr-service .cache{
    position: absolute;
    left: 0;
    top: 9vh;
    width: 100%;
    height: 60%;
    transition: all .8s ease-out 0s;
    padding: 69px 0px 5px 10px;
  }
  .headerr-service.active .cache{
    transition: all .8s ease-out .3s;
    left: -100%;
  }
  .cache:before{
    content: '';
    position: absolute;
    right: 0;
    top: 80%;
    transform: translate(0, -50%);
    width: 1%;
    height: 0;
    transition: all .3s ease-out .8s;
    background-color: #FAD605;
   
  }
  .headerr-service.active .cache:before{
    height: 100%;
    transition: all .3s ease-out 0s;
  }
  .headerr-service h1{
    font-weight: 10;
    margin: 0;
    font-size: 20px;
    text-align: start;
    padding: 69px 0px 5px 10px;
  }
  .headerr-service h5{
    margin: 0;
      color: gray;
      font-size: 15px;
      text-align: start;
      padding: 0px 0px 0px 10px;
  }
 
 
figure.snip1107 {
  font-family: 'Raleway', Arial, sans-serif;
  color: #fff;
  position: relative;
  overflow: hidden;
  margin: 10px;
  
  width: 100%;
  height: 100%;
  background: #000000;
  border-radius: 2%;
  text-align: center;
}
figure.snip1107 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
figure.snip1107 img {
  opacity: 1;
  width: 100%;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
}
figure.snip1107 figcaption {
  position: absolute;
  bottom: 0;
  left: 0%;
  right: 0%;
  top: 15%;
  height: 100%;
  width: 100%;
}
figure.snip1107 figcaption > div {
  overflow: hidden;
  width: 100%;
  position: relative;
  height: 60%;
}
figure.snip1107 h3{
    height: 100%;
    font-size: 1.2rem;
    
}
figure.snip1107 span {
    
}
figure.snip1107 h3,
figure.snip1107 span {
  margin: 0;
  padding: 10px 15px;
  display: inline-block;
  width: 100%;
  text-transform: uppercase;
}
figure.snip1107 span {
  background: #ffffff;
  color: #4d4d4d;
  position: relative;
  bottom: 0;
  left: 0;
  position: absolute;
  font-size: 15px;
  -webkit-transform: translate3d(0%, 100%, 0);
  transform: translate3d(0%, 100%, 0);
}
figure.snip1107 h3 {
  background: #333333;

}
figure.snip1107 a {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  color: #ffffff;
}
figure.snip1107.blue {
  background: #0a212f;
}
figure.snip1107.blue h3 {
    background: rgba(226, 226, 226, 0.9);
    height: 100%;
    color: #004e7f;
}


</style>